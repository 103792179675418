import {times} from 'lodash';
import React, {useEffect} from 'react';
import {motion, useAnimation} from 'framer-motion';
import getRandomInteger from '../utils/getRandomInteger';
import usePrevious from '../services/usePrevious';
import RemixIcon from './RemixIcon';

const baseHeartColors = ['var(--like-color)', 'var(--like-color)'];

const defaultRandomRange = [20, 60];

const getRandomAnimatedPositions = (randomRange: number[]) => {
  return times(5, () => {
    const x = getRandomInteger(...randomRange);
    const y = getRandomInteger(...randomRange);

    return [x % 2 ? x : -x, y % 2 ? y : -y];
  });
};

type Props = {
  brandStyle: BrandStyle;
  active: boolean;
  heartSize?: number;
  duration?: number;
};

const AnimatedHearts = ({brandStyle, active, heartSize, duration}: Props) => {
  const previousActive = usePrevious(active);
  const controls = useAnimation();

  useEffect(() => {
    if (previousActive !== active) {
      const positions = getRandomAnimatedPositions(heartSize ? [heartSize / 2, heartSize * 2] : defaultRandomRange);

      if (active) {
        controls.set({x: 0, y: 0, opacity: 1});
        controls.start((i) => ({
          x: positions[i][0],
          y: positions[i][1],
          opacity: 0
        }));
      } else {
        controls.set((i) => ({x: positions[i][0], y: positions[i][1], opacity: 0}));
        controls.start({
          x: 0,
          y: 0,
          opacity: 1
        });
      }
    }
  }, [active]);

  const heartColors = [...baseHeartColors, brandStyle.primaryColor, brandStyle.primaryColor, brandStyle.lightColor];

  return (
    <div className="top-px left-0 absolute w-full h-full">
      {times(5).map((index) => (
        <motion.div
          key={index}
          style={{position: 'absolute', scale: 0.7, opacity: 0, top: 1}}
          custom={index}
          animate={controls}
          transition={{duration: duration || 0.7}}
        >
          <RemixIcon name="heart-fill" color={heartColors[index]} size={heartSize} />
        </motion.div>
      ))}
    </div>
  );
};

export default AnimatedHearts;
