import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';
import AnimatedLoader from './AnimatedLoader';

const hoveredBgColor = 'rgba(0, 0, 0, 0.03)';

const ButtonContainer = styled.button<{hovered?: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--brand-radius);
  background-color: ${(props) => (props.hovered ? hoveredBgColor : 'transparent')};
  color: var(--gray-text);
  text-decoration: none;
  border: none;
  font-size: var(--button-text);
  font-weight: 500;
  height: 38px;
  padding: 0 16px;
  user-select: none;
  transition: background-color 0.2s;
  line-height: 1;
  flex-shrink: 0;

  &:hover {
    background-color: ${hoveredBgColor};
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &.focus-visible {
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 2px solid rgba(0, 0, 0, 0.05);
      border-radius: ${(props) =>
        props.style?.borderRadius ? `${props.style?.borderRadius}px` : 'var(--brand-radius)'};
    }
  }
`;

const InnerContent = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type TextButtonProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  // TODO: Fix this type
  // onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onClick?: any;
  replace?: boolean;
  as?: any;
  hovered?: boolean;
  type?: string;
  href?: string;
  target?: string;
  title?: string;
  disabled?: boolean;
  className?: string;
  layout?: boolean;
  locale?: string;
  shallow?: boolean;
  isLoading?: boolean;
  id?: string;
};

const TextButton = ({
  children,
  style,
  onClick,
  as,
  replace,
  hovered,
  type,
  href,
  target,
  title,
  disabled,
  className,
  layout,
  locale,
  shallow,
  isLoading,
  contentStyle,
  id
}: TextButtonProps) => {
  const innerContent = (
    <>
      <InnerContent
        initial={false}
        animate={{opacity: isLoading ? 0 : 1, transform: isLoading ? 'scaleX(0.2)' : 'scaleX(1)'}}
        style={contentStyle}
      >
        {children}
      </InnerContent>

      <AnimatePresence initial={false}>
        {isLoading ? (
          <AnimatedLoader style={{backgroundColor: style ? style.color || 'var(--gray-text)' : 'var(--gray-text)'}} />
        ) : null}
      </AnimatePresence>
    </>
  );

  if (href) {
    return (
      <ButtonContainer
        as={Link}
        shallow={shallow}
        href={href}
        forwardedAs={as}
        replace={replace}
        locale={locale}
        style={disabled ? {...style, opacity: 0.6} : style}
        className={`button ${className || ''}`}
        onClick={disabled ? undefined : onClick}
        target={target}
        title={title}
        hovered={hovered ? '1' : undefined}
        tabIndex={0}
        id={id}
      >
        {innerContent}
      </ButtonContainer>
    );
  }

  return (
    <ButtonContainer
      id={id}
      href={href}
      as={as}
      layout={layout}
      target={target}
      title={title}
      hovered={hovered ? '1' : undefined}
      style={disabled ? {...style, opacity: 0.6} : style}
      type={type}
      className={`button ${className || ''}`}
      onClick={disabled ? undefined : onClick}
      tabIndex={0}
    >
      {innerContent}
    </ButtonContainer>
  );
};

export default TextButton;
