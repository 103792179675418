import React, {useState} from 'react';
import styled from 'styled-components';
import Tippy, {TippyProps} from '@tippyjs/react';
import {AnimatePresence, motion} from 'framer-motion';
import TextButtonCompact from './TextButtonCompact';
import RemixIcon from './RemixIcon';

import 'tippy.js/dist/tippy.css';

const StyleButtonWrapper = styled.div``;

const buttonStyle: React.CSSProperties = {
  width: '100%',
  height: 'initial'
};

const buttonContentStyle: React.CSSProperties = {
  width: '100%',
  alignItems: 'flex-start',
  flexDirection: 'column',
  paddingRight: 12
};

const TopButtonRow = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 8px 0;
  line-height: 1.2;

  .h-icon {
    width: 22px;
    height: 22px;
  }

  & > svg {
    fill: var(--dark-text);
  }
`;

const Description = styled.span`
  margin-left: 0 !important;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.3;
  margin-bottom: 10px;
  text-align: left;
`;

export type ContextButton = {
  text?: string;
  Icon?: React.ReactNode;
  icon?: any;
  iconName?: string;
  onClick?: () => void;
  href?: string;
  color?: string;
  disabled?: boolean;
  title?: string;
  description?: string;
  target?: string;
  keepOpenOnClick?: boolean;
  useATag?: boolean;
  locale?: string;
  exact?: boolean;
  Component?: React.ReactNode;
};

type Props = {
  buttons: ContextButton[];
  tippyProps?: TippyProps;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  maxWidth?: string | number;
};

const LegacyContextMenu = ({buttons, tippyProps, style, children, maxWidth}: Props) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = (event) => {
    event.stopPropagation();
    setOpen(!isOpen);
  };

  return (
    <Tippy
      maxWidth={maxWidth || 280}
      interactive
      arrow={false}
      placement="bottom-end"
      {...tippyProps}
      animation="shift-away"
      duration={[140, 240]}
      visible={isOpen}
      theme="light-menu"
      zIndex={20}
      onClickOutside={() => setOpen(false)}
      content={
        <AnimatePresence initial={false}>
          {isOpen ? (
            <motion.div animate={{opacity: 1}} exit={{opacity: 0}} style={{zIndex: 12}}>
              {buttons.map((button, i) => {
                if (button.Component) return button.Component;

                const onClick = (e) => {
                  e.stopPropagation();
                  if (button.disabled) return;

                  if (button.onClick) button.onClick();
                  if (!button.keepOpenOnClick) setOpen(false);
                };

                return (
                  <StyleButtonWrapper
                    color={button.color || 'var(--dark-text)'}
                    key={button.text}
                    style={{opacity: button.disabled ? 0.6 : 1}}
                  >
                    <TextButtonCompact
                      title={button.title}
                      href={button.disabled ? undefined : button.href}
                      locale={button.locale}
                      target={button.target}
                      onClick={onClick}
                      style={buttonStyle}
                      contentStyle={buttonContentStyle}
                    >
                      <TopButtonRow style={{color: button.color || 'var(--dark-text)'}}>
                        {button.Icon}
                        {button.icon || button.iconName ? (
                          <RemixIcon
                            icon={button.icon}
                            name={button.iconName}
                            color={button.color || 'var(--dark-text)'}
                            size={20}
                          />
                        ) : null}
                        {button.Icon || button.icon || button.iconName ? <div style={{width: 12}} /> : null}
                        <span>{button.text}</span>
                      </TopButtonRow>
                      {button.description ? <Description>{button.description}</Description> : null}
                    </TextButtonCompact>
                  </StyleButtonWrapper>
                );
              })}
            </motion.div>
          ) : null}
        </AnimatePresence>
      }
    >
      <div style={style} onClick={children ? toggle : undefined}>
        {children ? (
          children
        ) : (
          <TextButtonCompact onClick={toggle} style={{borderRadius: 30, width: 38, height: 38}}>
            <RemixIcon name="more-2-fill" color="var(--gray-text)" size={18} />
          </TextButtonCompact>
        )}
      </div>
    </Tippy>
  );
};

export default LegacyContextMenu;
