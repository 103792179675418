import React from 'react';
import TextButton, {TextButtonProps} from './TextButton';
import {brandRadius} from '../utils/constants';

const TextButtonCompact = (props: TextButtonProps) => {
  return (
    <TextButton {...props} style={{padding: '0 10px', height: 30, borderRadius: brandRadius - 2, ...props.style}}>
      {props.children}
    </TextButton>
  );
};

export default TextButtonCompact;
